<template>
  <v-card light :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>
        Promotions <span v-if="!isNew"> - {{ form.promotionKey }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            fab
            small
            @click="showPublishingDialog()"
            v-bind="attrs"
            :disabled="!validAll || promotionUploads.existing.length == 0"
            v-on="on"
          >
            <v-icon v-if="form.publishDate">mdi-cloud-download-outline</v-icon>
            <v-icon v-if="!form.publishDate">mdi-cloud-upload-outline</v-icon>
          </v-btn>
        </template>
        <span v-if="form.publishDate">Unpublish</span>
        <span v-if="!form.publishDate">Publish</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isEditing && !this.isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="dialogDuplicate = true" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-duplicate</v-icon>
          </v-btn>
        </template>
        <span>Duplicate Promotion</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!validAll" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>Save Promotion</span>
      </v-tooltip>
    </v-toolbar>
    <ApiError :errors="computedErrors"></ApiError>
    <v-card-text>
      <v-stepper v-model="step" alt-labels v-if="!loading">
        <v-stepper-header>
          <v-stepper-step :editable="step > 1" step="1">
            <v-badge dot color="error" v-if="!valid.step1">
              Details
            </v-badge>
            <span v-else>{{ $i18n.translate("Details") }}</span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="step > 2 || valid.step1" step="2">
            <v-badge dot color="error" v-if="!valid.step2">
              Timeline
            </v-badge>
            <span v-else>Timeline</span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="step > 3 || (valid.step1 && valid.step2)" step="3">
            <v-badge dot color="error" v-if="!valid.step3">
              Audience
            </v-badge>
            <span v-else>Audience</span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="step > 4 || (valid.step1 && valid.step2 && valid.step3)" step="4">
            <v-badge dot color="error" v-if="!valid.step4">
              Products
            </v-badge>
            <span v-else>Products</span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="step > 5 || (valid.step1 && valid.step2 && valid.step3 && valid.step4)" step="5">
            <v-badge dot color="error" v-if="!valid.step5">
              Claiming
            </v-badge>
            <span v-else>Claiming</span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :editable="step > 6 || (valid.step1 && valid.step2 && valid.step3 && valid.step4 && valid.step5)"
            step="6"
            >Review</v-stepper-step
          >
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="detailsForm" @submit.prevent="step++" v-if="!loading" v-model="valid.step1">
              <v-container>
                <v-row>
                  <v-col cols="4">
                    <PromotionTypeField
                      v-model="selectedPromotionType"
                      :label="$i18n.translate('Promotion Type')"
                      :disabled="!isEditing"
                      :rules="rules.promotionType"
                      class="required"
                      @change="fetchAvailableAwardVehicles"
                    ></PromotionTypeField>
                  </v-col>
                  <v-col cols="4" v-if="selectedPromotionType">
                    <KeyField
                      maxLength="10"
                      counter="10"
                      :label="$i18n.translate('Promotion Key')"
                      hint="Uniquely identifies a promotion."
                      v-model="form.promotionKey"
                      @change="validateSalesNewsNumber"
                      customError="Promotion Number is required"
                      :error-messages="$error.getValidationError(errors, 'promotionKey')"
                      @input="$error.clearValidationError(errors, 'promotionKey')"
                      :rules="rules.promotionKey"
                      class="required"
                    ></KeyField>
                  </v-col>
                  <v-col cols="4" v-if="selectedPromotionType">
                    <CountryField
                      v-model="form.country"
                      :label="$i18n.translate('Country')"
                      :disabled="!isEditing || existingClaims"
                      :available="selectedPromotionType.availableCountries"
                      :rules="rules.country"
                      :program="selectedProgram"
                      class="required"
                    ></CountryField>
                  </v-col>
                </v-row>
                <v-row v-if="selectedPromotionType">
                  <v-col cols="6">
                    <v-text-field
                      maxLength="255"
                      :label="$i18n.translate('Promotion Name')"
                      hint="The displayed title of the Salesnews."
                      v-model="form.name"
                      :rules="rules.name"
                      class="required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <AwardVehicleField
                      v-model="form.forcedAwardVehicle"
                      :label="$i18n.translate('Award Vehicle')"
                      :disabled="!isEditing"
                      :available="availableAwardVehicles"
                      hint="Leave blank to reward based on participant preference"
                    ></AwardVehicleField>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <PromotionAudienceTypeField
                      id="promotionAudienceType"
                      name="promotionAudienceType"
                      label="Audience Type"
                      v-model="form.promotionAudienceType"
                      :disabled="!isEditing"
                    />
                  </v-col>
                </v-row>

                <v-row v-if="selectedPromotionType && hasCustomFields">
                  <v-col cols="4" v-for="(customField, index) in selectedProgram.promotionCustomFields" :key="index">
                    <CustomField
                      v-model="form[`customFieldValue${index + 1}`]"
                      :disabled="!isEditing"
                      :customField="customField"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="selectedPromotionType">
                  <v-col cols="12">
                    <v-textarea
                      counter
                      :label="$i18n.translate('Promotion Description (HTML allowed)')"
                      hint="This description is typically shown for End User Salesnews"
                      v-model="form.description"
                      :rules="rules.description"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6">
                    <v-card>
                      <v-card-title>Preview</v-card-title>
                      <v-card-text v-html="form.description"></v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-if="selectedPromotionType">
                  <v-col cols="12">
                    <UploadField
                      v-model="promotionUploads"
                      :disabled="!isEditing"
                      :rules="[requireUpload]"
                      :limitNumberFile="selectedPromotionType.uploadLimit"
                      @change="dirty = true"
                    ></UploadField>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-switch
                      dense
                      label="Publicly Invitable?"
                      v-model="form.publiclyInvitable"
                      :disabled="!isEditing"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6">
                    <v-switch dense label="Taxable?" v-model="form.taxable" :disabled="!isEditing"></v-switch>
                  </v-col>
                </v-row>
              </v-container>
              <v-btn color="primary" :disabled="!valid.step1" @click="step++">
                Continue
              </v-btn>
              <v-btn text @click="onCancel">{{ $i18n.translate("Cancel") }}</v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-form @submit.prevent="step++" v-if="!loading" v-model="valid.step2">
              <v-container>
                <v-row>
                  <v-col cols="3">
                    <DateTimePickerField
                      v-model="form.effectiveDate"
                      :rules="rules.effectiveDate"
                      label="Promotion Start Date"
                      required
                      defaultTime="00:00:00"
                    />
                  </v-col>
                  <v-col cols="3">
                    <DateTimePickerField
                      v-model="form.expirationDate"
                      :rules="rules.expirationDate"
                      label="Promotion End Date"
                      required
                      defaultTime="23:59:58"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <PromotionDeadlinesTable
                      :promotionId="form.id"
                      :disabled="!isEditing"
                      v-model="promotionDeadlines"
                      :generateButtonEnabled="form.expirationDate != null && form.effectiveDate != null"
                      :deadlinesStart="form.effectiveDate"
                      :deadlinesEnd="form.expirationDate"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-btn color="primary" :disabled="!valid.step2" @click="step++">
                {{ $i18n.translate("Continue") }}
              </v-btn>
              <v-btn text @click="onCancel">{{ $i18n.translate("Cancel") }}</v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-form ref="audienceForm" @submit.prevent="step++" v-if="!loading" v-model="valid.step3">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <OrganizationTypesField
                      v-model="selectedOrganizationTypes"
                      label="Included Company Types"
                      :disabled="!isEditing"
                      :rules="[validateAudience]"
                      @change="dirty = true"
                    />
                    <OrganizationGroupsField
                      v-model="selectedOrganizationGroups"
                      label="Included CPGs"
                      :disabled="!isEditing"
                      :rules="[validateAudience]"
                      category="CPG"
                      @change="dirty = true"
                    />
                    <ParticipantTypesField
                      v-model="selectedParticipantTypes"
                      label="Included Participant Types"
                      :disabled="!isEditing"
                      :rules="[validateAudience]"
                      multiple
                      @change="dirty = true"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-switch label="Invitation Only?" v-model="form.invitationOnly" :disabled="!isEditing" />
                  </v-col>
                </v-row>

                <v-row v-if="showPromotionInvitationSetting">
                  <v-col cols="12">
                    <v-card variant="outlined">
                      <v-card-title>Promotion Invitation Setting</v-card-title>

                      <PromotionInvitationConfigForm
                        v-model="selectedPromotionInvitationSetting"
                        :disabled="!isEditing"
                      />
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-switch
                      label="Include / Exclude certain Participants?"
                      v-model="participantInclusionsExclusions"
                    />
                  </v-col>
                </v-row>
                <v-row v-show="participantInclusionsExclusions">
                  <v-col cols="12">
                    <PromotionParticipantsTable
                      label="Include/Exclude Certain Participants"
                      :promotionId="promotionId"
                      :disabled="!isEditing"
                      :rules="[validateAudience]"
                      v-model="promotionParticipants"
                      :isCopy="isCopy"
                      @change="dirty = true"
                      ref="promotionParticipantsTable"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch label="Include / Exclude certain Companies?" v-model="organizationInclusionsExclusions" />
                  </v-col>
                </v-row>
                <v-row v-show="organizationInclusionsExclusions">
                  <v-col cols="12">
                    <PromotionOrganizationsTable
                      label="Include/Exclude Certain Companies"
                      :promotionId="promotionId"
                      :disabled="!isEditing"
                      :isCopy="isCopy"
                      :rules="[validateAudience]"
                      v-model="promotionOrganizations"
                      @change="dirty = true"
                      ref="promotionOrganizationsTable"
                    />
                  </v-col>
                </v-row>
              </v-container>

              <v-btn color="primary" :disabled="!valid.step3" @click="step++">
                {{ $i18n.translate("Continue") }}
              </v-btn>

              <v-btn text @click="onCancel">{{ $i18n.translate("Cancel") }}</v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-form ref="productForm" @submit.prevent="step++" v-if="!loading" v-model="valid.step4">
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <PromotionsField
                      label="Promotions to exclude in duplication checks"
                      v-model="selectedDuplicationCheckPromotions"
                      :showKey="true"
                      :status="'All'"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <PromotionProductsTable
                      v-if="form.country"
                      v-model="promotionProducts"
                      :availableAwardVehicles="availableAwardVehicles"
                      :disabled="!isEditing"
                      :isRuleEngineBased="selectedPromotionType.rulesEngineBased"
                      :promotionEffectiveDate="form.effectiveDate"
                      :promotionExpirationDate="form.expirationDate"
                      :promotionCountry="form.country"
                      :promotionCurrency="form.country.currencyName"
                      :simplePayouts="true"
                      @change="dirty = true"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <PromotionLimit
                      :promotion="form"
                      ref="promotionLimitComponent"
                      :editing="isEditing"
                      :showEditButton="false"
                      :showAddButton="true"
                      :showSaveButton="false"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <RuleGroupTable :promotion="form" :editing="isEditing" />
                  </v-col>
                </v-row>
              </v-container>

              <v-btn color="primary" :disabled="!valid.step4 || promotionProducts.existing.length == 0" @click="step++">
                {{ $i18n.translate("Continue") }}
              </v-btn>

              <v-btn text @click="onCancel">{{ $i18n.translate("Cancel") }}</v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="5">
            <v-form @submit.prevent="step++" v-if="!loading" v-model="valid.step5">
              <v-container>
                <v-row>
                  <v-col cols="4">
                    <ClaimTypeField
                      label="Claim Type"
                      hint="Determines claim fields and claim stages"
                      v-model="selectedClaimType"
                      :rules="rules.claimType"
                      @input="fetchClaimFields"
                      class="required"
                      :disabled="existingClaims"
                    />
                    <v-alert v-if="existingClaims" type="warning">
                      The Claim Type can't be changed when there are existing claims for the promotion.
                    </v-alert>
                  </v-col>
                  <v-col cols="8">
                    <v-data-table
                      :items="claimFields"
                      :headers="claimFieldHeaders"
                      no-data-text="No Claim Fields are included for this claim type"
                      :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
                    >
                      <template v-slot:item.required="{ item }">
                        <v-icon v-if="item.required" color="green">mdi-check</v-icon>
                        <v-icon v-else color="red">mdi-onClose</v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>

              <v-btn color="primary" :disabled="!valid.step5" @click="step++">
                {{ $i18n.translate("Continue") }}
              </v-btn>

              <v-btn text @click="onCancel">{{ $i18n.translate("Cancel") }}</v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="6">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title primary-title>
                      Details
                    </v-card-title>
                    <v-card-text>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <th width="30%" class="text-right">
                                Type
                              </th>
                              <td width="70%">{{ selectedPromotionType ? selectedPromotionType.name : "" }}</td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Promotion Number
                              </th>
                              <td width="70%">{{ form.promotionKey }}</td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Promotion Title
                              </th>
                              <td width="70%">{{ form.name }}</td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Promotion Files
                              </th>
                              <td width="70%">
                                <ol>
                                  <li v-for="(upload, i) in promotionUploads.existing" :key="i + '-uploads'">
                                    <a target="_blank" rel="noreferrer" :href="upload.href">{{
                                      upload.originalFilename
                                    }}</a>
                                  </li>
                                </ol>
                              </td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Promotions to exclud in duplication checks
                              </th>
                              <td width="70%">
                                <span
                                  v-for="(duplicationCheckPromotion, i) in selectedDuplicationCheckPromotions"
                                  :key="i"
                                >
                                  {{ duplicationCheckPromotion.name }} - {{ duplicationCheckPromotion.promotionKey }}
                                  <br />
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Forced Award Vehicle
                              </th>
                              <td width="70%">
                                {{
                                  form.forcedAwardVehicle
                                    ? form.forcedAwardVehicle.name
                                    : "None, use participant preference"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title primary-title>
                      Timelines
                    </v-card-title>
                    <v-card-text>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <th width="30%" class="text-right">
                                Promotion Timeline
                              </th>
                              <td width="70%">
                                {{ form.effectiveDate | formatDateClient("MM/DD/YYYY", selectedClient) }} -
                                {{ form.expirationDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
                              </td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Publish Date
                              </th>
                              <td width="70%">
                                {{ form.publishDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
                              </td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Claiming Deadlines
                              </th>
                              <td width="70%">
                                <PromotionDeadlinesTable
                                  :promotionId="promotionId"
                                  :disabled="!isEditing"
                                  v-model="promotionDeadlines"
                                  hide-title
                                  hide-actions
                                  @validation="promotionDeadlinesTableValid = $event"
                                  @change="dirty = true"
                                ></PromotionDeadlinesTable>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title primary-title>
                      Audience
                    </v-card-title>
                    <v-card-text>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <th width="30%" class="text-right">
                                Included Company Types
                              </th>
                              <td width="70%">
                                <span v-for="(organizationType, i) in selectedOrganizationTypes" :key="i">
                                  {{ organizationType.name }} <br />
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Included CPGs
                              </th>
                              <td width="70%">
                                <span v-for="(organizationGroup, i) in selectedOrganizationGroups" :key="i">
                                  {{ organizationGroup.name }} - {{ organizationGroup.description }}<br />
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Included Participant Types
                              </th>
                              <td width="70%">
                                <span v-for="(participantType, i) in selectedParticipantTypes" :key="i">
                                  {{ participantType.name }}<br />
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Included Participants
                              </th>
                              <td width="70%">
                                <span v-for="(promotionParticipant, i) in includedParticipants" :key="i">
                                  {{ promotionParticipant.participant.fullName }}
                                  <span v-if="promotionParticipant.participant.organization"
                                    >({{ promotionParticipant.participant.organization.name }})</span
                                  >
                                  <br />
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Excluded Participants
                              </th>
                              <td width="70%">
                                <span v-for="(promotionParticipant, i) in excludedParticipants" :key="i">
                                  {{ promotionParticipant.participant.fullName }}
                                  <span v-if="promotionParticipant.participant.organization"
                                    >({{ promotionParticipant.participant.organization.name }})</span
                                  >
                                  <br />
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Included Companies
                              </th>
                              <td width="70%">
                                <span v-for="(promotionOrganization, i) in includedOrganizations" :key="i">
                                  {{ promotionOrganization.organization.name }}
                                  ({{ promotionOrganization.organization.organizationKey }})
                                  <br />
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th width="30%" class="text-right">
                                Excluded Companies
                              </th>
                              <td width="70%">
                                <span v-for="(promotionOrganization, i) in excludedOrganizations" :key="i">
                                  {{ promotionOrganization.organization.name }}
                                  ({{ promotionOrganization.organization.organizationKey }})
                                  <br />
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title primary-title>
                      Products
                    </v-card-title>
                    <v-card-text>
                      <PromotionProductsTable
                        v-if="form.country"
                        :promotionId="promotionId"
                        :disabled="!isEditing"
                        v-model="promotionProducts"
                        hide-title
                        hide-actions
                        mobile-breakpoint="800"
                        :key="step"
                        :promotionCountry="form.country"
                        :promotionCurrency="form.country.currencyName"
                        @change="dirty = true"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title primary-title>
                      Claim Fields ({{ selectedClaimType ? selectedClaimType.name : "" }})
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :items="claimFields"
                        :headers="claimFieldHeaders"
                        no-data-text="No Claim Fields are included for this claim type"
                        mobile-breakpoint="800"
                        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
                      >
                        <template v-slot:item.required="{ item }">
                          <v-icon v-if="item.required" color="green">mdi-check</v-icon>
                          <v-icon v-else color="red">mdi-onClose</v-icon>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

            <v-btn color="primary" :disabled="!valid" @click="onSubmit">
              Submit
            </v-btn>

            <v-btn text @click="onCancel">{{ $i18n.translate("Cancel") }}</v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <!--<v-btn>{{ $i18n.translate("Save") }}</v-btn>-->
    </v-card-actions>
    <v-dialog v-model="dialogPublish" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          Choose an option
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="publishOption">
            <v-radio label="Publish immediately" value="1" />
            <v-radio label="Publish on a future date" value="2" /><br />
            <DateTimePickerField
              v-if="publishOption == 2"
              v-model="publishDate"
              :rules="rules.publishDate"
              label="Promotion Publish Date"
              hint="When the promotion becomes visible to claim"
              defaultTime="00:00:00"
            />
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="primary"
            @click="
              publishOption = null;
              dialogPublish = false;
            "
          >
            {{ $i18n.translate("Cancel") }}
          </v-btn>
          <v-btn
            class="primary"
            @click="
              onPublish();
              publishOption = null;
              dialogPublish = false;
            "
            :disabled="publishOption == null"
          >
            {{ $i18n.translate("Accept") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogUnpublish" persistent max-width="500">
      <v-card>
        <v-card-title style="headline">Confirmation</v-card-title>
        <v-card-text>
          {{
            $i18n.translate(
              "Are you sure you want to unpublish this Promotion? It will no longer be visible to claim against."
            )
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn class="primary" @click="dialogUnpublish = false">No</v-btn>
          <v-btn
            class="primary"
            @click="
              onUnpublish();
              dialogUnpublish = false;
            "
          >
            {{ $i18n.translate("Yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmPublish" persistent max-width="500">
      <v-card>
        <v-card-title style="headline">Confirmation</v-card-title>
        <v-card-text>
          This Promotion is currently NOT PUBLISHED - would you like to PUBLISH now?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="primary" @click="onDialogConfirmPublishNo">No</v-btn>
          <v-btn
            class="primary"
            @click="
              dialogConfirmPublish = false;
              dialogPublish = true;
            "
          >
            {{ $i18n.translate("Yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDuplicate" persistent max-width="500">
      <v-card>
        <v-card-title>
          Duplicate Promotion
        </v-card-title>
        <v-card-text>
          Do you want to create a new Promotion by duplicating this Promotion?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="dialogDuplicate = false">
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              dialogDuplicate = false;
              duplicate();
            "
          >
            {{ $i18n.translate("Yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSaveChanges" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">
          Changes have been made
        </v-card-title>
        <v-card-subtitle> </v-card-subtitle>
        <v-card-text>
          <span v-if="!validAll">
            If you exit now all changes will be discarded.
          </span>
          <span v-else>
            Do you want to save changes before leaving the page?
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary" @click="dialogSaveChanges = false">{{ $i18n.translate("Continue editing") }}</v-btn>
          <v-btn class="primary" @click="toUrl">{{ $i18n.translate("Exit without saving") }}</v-btn>
          <v-btn
            class="primary"
            v-if="validAll"
            @click="
              onSubmit();
              toUrl();
            "
          >
            {{ $i18n.translate("Save and exit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import AwardVehicleField from "@/gapp-components/components/fields/AwardVehicleField.vue";
import ClaimTypeField from "@/gapp-components/components/fields/ClaimTypeField.vue";
import CountryField from "@/gapp-components/components/fields/CountryField.vue";
import CustomField from "@/gapp-components/components/fields/CustomField.vue";
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField.vue";
import KeyField from "@/gapp-components/components/fields/KeyField.vue";
import OrganizationGroupsField from "@/gapp-components/components/fields/OrganizationGroupsField.vue";
import OrganizationTypesField from "@/gapp-components/components/fields/OrganizationTypesField.vue";
import ParticipantTypesField from "@/gapp-components/components/fields/ParticipantTypesField.vue";
import PromotionsField from "@/gapp-components/components/fields/PromotionsField.vue";
import PromotionTypeField from "@/gapp-components/components/fields/PromotionTypeField.vue";
import UploadField from "@/gapp-components/components/fields/UploadField.vue";
import PromotionInvitationConfigForm from "@/gapp-components/components/forms/PromotionInvitationConfigForm.vue";
import PromotionLimit from "@/gapp-components/components/sony/PromotionLimit.vue";
import PromotionDeadlinesTable from "@/gapp-components/components/tables/PromotionDeadlinesTable.vue";
import PromotionOrganizationsTable from "@/gapp-components/components/tables/PromotionOrganizationsTable.vue";
import PromotionParticipantsTable from "@/gapp-components/components/tables/PromotionParticipantsTable.vue";
import PromotionProductsTable from "@/gapp-components/components/tables/PromotionProductsTable.vue";
import RuleGroupTable from "@/gapp-components/components/tables/RuleGroupTable.vue";
import PromotionAudienceTypeField from "@/gapp-components/components/fields/PromotionAudienceTypeField.vue";
import moment from "moment-timezone";

import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PromotionTypeField,
    KeyField,
    CountryField,
    AwardVehicleField,
    DateTimePickerField,
    PromotionDeadlinesTable,
    OrganizationTypesField,
    OrganizationGroupsField,
    ParticipantTypesField,
    PromotionInvitationConfigForm,
    PromotionParticipantsTable,
    PromotionOrganizationsTable,
    PromotionsField,
    RuleGroupTable,
    PromotionProductsTable,
    PromotionLimit,
    ClaimTypeField,
    ApiError,
    UploadField,
    CustomField,
    PromotionAudienceTypeField
  },
  name: "SalesNews",
  metaInfo: {
    title: "Promotion"
  },
  data: () => ({
    step: "1",
    valid: {
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false
    },
    dirty: false,
    toUrl: null,
    ruleGroup: null,
    loading: true,
    isNew: false,
    isCopy: false,
    isEditing: true,
    promotionId: 0,
    errors: {},
    publishDate: null,
    hasProductPayouts: false,
    existingClaims: false,
    form: {
      claimDeadlines: 4,
      submissionDeadlineDays: 45,
      effectiveDate: null,
      expirationDate: null
    },

    rules: {
      promotionType: [v => !!v || "Promotion Type is required"],
      promotionKey: [v => !!v || "Promotion Number is required"],
      name: [v => !!v || "Promotion Title is required"],
      country: [v => !!v || "Country is required"],

      effectiveDate: [v => !!v || "Start Date is required"],
      expirationDate: [v => !!v || "End Date is required"],

      claimType: [v => !!v || "Claim Type is required"],
      description: [v => !v || v.length <= 2000 || "Max 2,000 characters"]
    },

    availableAwardVehicles: [],
    selectedPromotionType: {
      availableCountries: [],
      uploadLimit: 0,
      rulesEngineBased: false,
      name: "",
      id: 0
    },
    selectedClaimType: null,
    selectedContest: null,
    selectedParticipantTypes: [],
    selectedOrganizationTypes: [],
    selectedOrganizationGroups: [],
    selectedDuplicationCheckPromotions: [],
    selectedDeadline: null,
    newDeadline: null,
    participantInclusionsExclusions: true,
    organizationInclusionsExclusions: true,
    dialogPublish: false,
    dialogUnpublish: false,
    dialogConfirmPublish: false,
    dialogSaveChanges: false,
    promotionDeadlinesTableValid: false,
    promotionDeadlines: {
      existing: [],
      deleted: []
    },
    promotionProducts: {
      existing: [],
      deleted: [],
      promotionProductGroups: []
    },
    promotionParticipants: {
      existing: [],
      deleted: []
    },
    promotionOrganizations: {
      existing: [],
      deleted: []
    },
    promotionUploads: {
      existing: [],
      deleted: []
    },

    regions: [
      { value: "USA", text: "United States" },
      { value: "CAN", text: "Canada" }
    ],
    fiscalYears: [],
    claimDeadlines: [
      { value: 4, text: "Every Quarter" },
      { value: 2, text: "Every Half" },
      { value: 1, text: "Single" }
    ],
    claimDeadlineDateHeaders: [
      {
        value: "invoiceDatesBetween",
        text: "Invoice Dates Between",
        sortable: false,
        align: "center"
      },
      {
        value: "submissionDeadlineDate",
        text: "Claiming Deadline",
        sortable: false,
        align: "center"
      }
    ],

    claimFields: [],
    claimFieldHeaders: [
      {
        value: "category",
        text: "Category"
      },
      {
        value: "name",
        text: "Field"
      },
      {
        value: "required",
        text: "Required?",
        align: "center"
      },
      {
        value: "",
        text: "Default Value"
      }
    ],
    dialogDuplicate: false,
    publishOption: null,
    newDeadlinesList: [],

    selectedPromotionInvitationSetting: {
      linkToInvitation: null,
      approvalsNeeded: 1,
      redeemable: false,
      defaultParticipantType: null,
      defaultParticipantStatus: null,

      participantRequesters: {
        existing: [],
        deleted: []
      },
      participantApprovers: {
        existing: [],
        deleted: []
      },
      participantTypesManagement: [],
      emailTemplate: null,
      approverEmailTemplate: null,
      rejectedEmailTemplate: null,
      redeemedEmailTemplate: null
    },
    publiclyInvitable: false
  }),
  created() {
    if (this.$route.params.id == 0) {
      this.isNew = true;
      if (this.$route.params.copyDataFromId) {
        this.isCopy = true;
        this.promotionId = parseInt(this.$route.params.copyDataFromId);
      }
    } else {
      this.promotionId = parseInt(this.$route.params.id);
    }
    this.fetchData().then(() => {
      if (this.isNew && !this.isCopy) {
        var d = new Date();
        var year = d.getFullYear();
        this.fiscalYears = [];
        this.fiscalYears.push({ value: year - 1, text: year - 1 });
        this.fiscalYears.push({ value: year, text: year });
        this.fiscalYears.push({ value: year + 1, text: year + 1 });
        this.form.fiscalYear = this.getFiscalYearByDate(d);
      }
    });
    this.form.effectiveDate = null;
    this.form.expirationDate = null;
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.dirty = true;
      }
    },
    promotionUploads: {
      handler() {
        if (this.$refs.detailsForm) {
          this.$refs.detailsForm.validate();
        }
      },
      deep: true
    },
    promotionProducts: {
      handler() {
        if (this.$refs.productsForm) {
          this.$refs.productsForm.validate();
        }
      },
      deep: true
    },
    promotionParticipants: {
      handler(v) {
        if (v & (v.existing.length > 0 || v.deleted.length > 0)) {
          this.participantInclusionsExclusions = true;
        }
      },
      deep: true
    },
    promotionOrganizations: {
      handler(v) {
        if (v & (v.existing.length > 0 || v.deleted.length > 0)) {
          this.organizationInclusionsExclusions = true;
        }
      },
      deep: true
    },
    selectedOrganizationTypes() {
      if (this.$refs.audienceForm) {
        this.$refs.audienceForm.validate();
      }
    },
    selectedOrganizationGroups() {
      if (this.$refs.audienceForm) {
        this.$refs.audienceForm.validate();
      }
    },
    selectedParticipantTypes() {
      if (this.$refs.audienceForm) {
        this.$refs.audienceForm.validate();
      }
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram", "selectedLocale"]),
    validAll() {
      return this.valid.step1 && this.valid.step2 && this.valid.step3 && this.valid.step4 && this.valid.step5;
    },
    includedOrganizationGroupsLabel() {
      return "Included " + this.$i18n.translate("Organization Groups");
    },
    includedParticipants() {
      let included = this.promotionParticipants.existing.reduce((acc, cur) => {
        if (cur.includeInPromotion) {
          acc.push(cur);
        }
        return acc;
      }, []);
      return included;
    },
    excludedParticipants() {
      return this.promotionParticipants.existing.reduce((acc, cur) => {
        if (!cur.includeInPromotion) {
          acc.push(cur);
        }
        return acc;
      }, []);
    },
    includedOrganizations() {
      let included = this.promotionOrganizations.existing.reduce((acc, cur) => {
        if (cur.includeInPromotion) {
          acc.push(cur);
        }
        return acc;
      }, []);
      return included;
    },
    excludedOrganizations() {
      return this.promotionOrganizations.existing.reduce((acc, cur) => {
        if (!cur.includeInPromotion) {
          acc.push(cur);
        }
        return acc;
      }, []);
    },
    computedErrors() {
      return this.errors;
    },
    currentYear() {
      return moment(new Date()).year();
    },
    currentFiscalYear() {
      return this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
    },
    showPromotionInvitationSetting() {
      return this.form.invitationOnly;
    },
    hasCustomFields() {
      return this.selectedProgram.promotionCustomFields && this.selectedProgram.promotionCustomFields.length > 0;
    }
  },
  methods: {
    onCancel() {
      this.$router.push({ name: "salesnewsLibrary" });
    },

    getPromotionForm() {
      let promotion = Object.assign({}, this.form);

      // If promotionAudienceType is not defined, use the default value CLIENT_PORTAL
      promotion.promotionAudienceType = promotion.promotionAudienceType
        ? promotion.promotionAudienceType
        : { name: "CLIENT_PORTAL" };

      // set promotion deadlines
      if (this.promotionDeadlines.existing) {
        promotion.promotionDeadlines = this.promotionDeadlines.existing;
      }

      // set currency, based on country
      if (promotion.country) {
        promotion.currency = { name: promotion.country.currencyName };
        promotion.country = { name: promotion.country.name };
      }

      // set upload
      if (this.promotionUploads) {
        promotion.uploads = this.promotionUploads.existing;
      }
      //set promotion type
      if (this.selectedClaimType) {
        promotion.claimType = this.selectedClaimType;
      }
      //set promotion type
      if (this.selectedPromotionType) {
        promotion.promotionType = this.selectedPromotionType;
      }
      // set selected participant Type
      if (this.selectedParticipantTypes) {
        promotion.participantTypes = this.selectedParticipantTypes;
      }
      // set selected organization groups
      if (this.selectedParticipantGroups) {
        promotion.participantGroups = this.selectedParticipantGroups;
      }
      // set selected organization types
      if (this.selectedOrganizationTypes) {
        promotion.organizationTypes = this.selectedOrganizationTypes;
      }
      // set selected organization groups
      if (this.selectedOrganizationGroups) {
        promotion.organizationGroups = this.selectedOrganizationGroups;
      }

      // set selected duplication check promotions
      if (this.selectedDuplicationCheckPromotions) {
        promotion.duplicationCheckPromotions = this.selectedDuplicationCheckPromotions;
      }

      // set selected default award vehicle
      if (this.form.forcedAwardVehicle) {
        promotion.forcedAwardVehicle = this.form.forcedAwardVehicle;
      }
      if (!this.isNew && this.form.forcedAwardVehicle == undefined) {
        promotion.forcedAwardVehicle = undefined;
      }

      // Set Promotion Invitation link - linkToInvitation
      if (this.selectedPromotionInvitationSetting.linkToInvitation) {
        promotion.promotionInvitationUrl = this.selectedPromotionInvitationSetting.linkToInvitation;
      }
      // Set Promotion Invitation - approvalsNeeded
      if (this.selectedPromotionInvitationSetting.approvalsNeeded) {
        promotion.promotionInvitationApprovalsNeeded = this.selectedPromotionInvitationSetting.approvalsNeeded;
      }

      // Set Promotion Invitation - Redeemable
      promotion.promotionInvitationRedeemable = this.selectedPromotionInvitationSetting.promotionInvitationRedeemable;

      //  Set Promotion Invitation - default participant type
      if (this.selectedPromotionInvitationSetting.defaultParticipantType) {
        promotion.invitationOnlyDefaultParticipantType = {
          id: this.selectedPromotionInvitationSetting.defaultParticipantType.id
        };
      }
      //  Set Promotion Invitation - default participant status
      if (this.selectedPromotionInvitationSetting.defaultParticipantStatus) {
        promotion.invitationOnlyDefaultParticipantStatus = {
          name: this.selectedPromotionInvitationSetting.defaultParticipantStatus.name
        };
      }

      // Set Promotion Invitation - requester participants
      if (this.selectedPromotionInvitationSetting.participantRequesters) {
        promotion.promotionInvitationParticipantRequesters = this.selectedPromotionInvitationSetting.participantRequesters.existing.map(
          p => {
            return { id: p.id };
          }
        );
      }

      // Set Promotion Invitation - approval participants
      if (this.selectedPromotionInvitationSetting.participantApprovers) {
        promotion.promotionInvitationParticipantApprovers = this.selectedPromotionInvitationSetting.participantApprovers.existing.map(
          p => {
            return { id: p.id };
          }
        );
      }

      // Set Promotion Invitation - Participant Type Management
      if (this.selectedPromotionInvitationSetting.participantTypesManagement) {
        promotion.promotionInvitationParticipantTypesManagement = this.selectedPromotionInvitationSetting.participantTypesManagement.map(
          p => {
            return { id: p.id };
          }
        );
      }

      // Set Promotion Invitation Email template to send invitation when is approved
      if (this.selectedPromotionInvitationSetting.emailTemplate) {
        promotion.promotionInvitationEmailTemplate = {
          id: this.selectedPromotionInvitationSetting.emailTemplate.id
        };
      }

      // Set Approver Email template
      if (this.selectedPromotionInvitationSetting.approverEmailTemplate) {
        promotion.promotionInvitationApproverEmailTemplate = {
          id: this.selectedPromotionInvitationSetting.approverEmailTemplate.id
        };
      }

      // Set Rejected Email Template
      if (this.selectedPromotionInvitationSetting.rejectedEmailTemplate) {
        promotion.promotionInvitationRejectedEmailTemplate = {
          id: this.selectedPromotionInvitationSetting.rejectedEmailTemplate.id
        };
      }

      // Set Redeemed Email Template
      if (this.selectedPromotionInvitationSetting.redeemedEmailTemplate) {
        promotion.promotionInvitationRedeemedEmailTemplate = {
          id: this.selectedPromotionInvitationSetting.redeemedEmailTemplate.id
        };
      }

      return promotion;
    },
    onSubmit() {
      this.loading = true;
      this.errors = {};

      let postForm = {};
      let promotionLimitComponentRef = this.$refs.promotionLimitComponent;
      if (this.promotionProducts) {
        let promotionProducts = [];
        let promotionProductGroups = [];
        this.promotionProducts.existing.forEach(promotionProduct => {
          if (promotionProduct.isGroup) {
            promotionProductGroups.push(promotionProduct);
          } else {
            promotionProducts.push(promotionProduct);
          }
        });
        postForm.promotionProducts = promotionProducts;
        postForm.promotionProductGroups = promotionProductGroups;
      }

      // set promotionParticipants
      if (this.promotionParticipants) {
        postForm.promotionParticipants = JSON.parse(JSON.stringify(this.promotionParticipants.existing));
        postForm.promotionParticipants.forEach(pp => {
          pp.participant = { id: pp.participant.id };
        });
      }

      // set promotion organizations
      if (this.promotionOrganizations) {
        postForm.promotionOrganizations = this.promotionOrganizations.existing;
      }

      postForm.promotion = this.getPromotionForm();
      if (this.selectedContest) {
        postForm.promotion.contest = { id: this.selectedContest.id };
      }

      let serviceCall = null;
      if (this.isNew) {
        serviceCall = this.$api.post("/api/promotions/submit", postForm);
      } else {
        serviceCall = this.$api.post("/api/promotions/" + this.$route.params.id + "/update", postForm);
      }
      serviceCall
        .then(({ data }) => {
          this.form = data;
          if (promotionLimitComponentRef) {
            promotionLimitComponentRef.onSubmit();
          }
          this.$route.params.id = data.id;
          this.deletePromotionDocument()
            .then(() => {
              this.dirty = false;
              if (!this.form.publishDate) {
                this.dialogConfirmPublish = true;
              } else {
                this.dialogConfirmPublish = false;
                this.$router.push({ name: "salesnewsLibrary" });
              }
            })
            .catch(error => {
              this.loading = false;
              this.isEditing = true;
              console.log(error);
              this.errors = this.$api.getErrorsFromResponse(error);
              this.fetchData();
            });
        })
        .catch(error => {
          this.loading = false;
          this.isEditing = true;
          console.log(error);
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },

    deletePromotionDocument() {
      if (this.promotionUploads.deleted) {
        let promiseArray = [];
        this.promotionUploads.deleted.forEach(document => {
          promiseArray.push(this.$api.delete("/api/uploads/remove/" + document.id));
        });
        return Promise.all(promiseArray);
      } else {
        return Promise.resolve();
      }
    },
    getFiscalYearByDate(date) {
      if (this.selectedClient.fiscalMonth && this.selectedClient.fiscalDay) {
        let year = moment(date).year();

        let fiscalYearStart = moment(
          year + "-" + this.selectedClient.fiscalMonth + "-" + this.selectedClient.fiscalDay,
          "YYYY-MM-DD"
        );

        if (moment(date).isBefore(fiscalYearStart)) {
          return year - 1;
        } else {
          return year;
        }
      }
    },

    fetchData() {
      if (!this.isNew) {
        this.loading = true;
        return this.$api
          .get("/api/promotions/" + this.$route.params.id)
          .then(({ data }) => {
            this.form = data;
            this.selectedPromotionInvitationSetting.linkToInvitation = data.promotionInvitationUrl;
            this.selectedPromotionInvitationSetting.approvalsNeeded = data.promotionInvitationApprovalsNeeded;
            this.selectedPromotionInvitationSetting.promotionInvitationRedeemable = data.promotionInvitationRedeemable;

            this.selectedPromotionInvitationSetting.defaultParticipantStatus =
              data.invitationOnlyDefaultParticipantStatus;

            this.breadcrumb();
            this.checkExistingClaims();
            return this.fetchRelatedObjectsAndSearch(data);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (this.isCopy) {
          this.loading = true;
          return this.$api
            .get("/api/promotions/" + this.$route.params.copyDataFromId)
            .then(({ data }) => {
              this.form = data;
              this.form.name = "Copy of " + this.form.name;
              this.form.promotionKey = undefined;
              this.form.publishDate = undefined;
              this.breadcrumb();
              return this.fetchRelatedObjectsAndSearch(data).then(() => {
                delete this.form.id;
                delete this.form._links;
                // Remove ids from entities so that they are taken as new by API
                // fetching of data for promotionParticipants is made by component so
                // the same it's being handled in the savePromotionParticipants method

                this.checkExistingClaims();
              });
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
          return Promise.resolve();
        }
      }
    },
    fetchRelatedObjectsAndSearch(data) {
      this.promotionProducts.existing = [];
      this.promotionProducts.deleted = [];

      return Promise.all([
        this.$api.getRelatedObject("promotionType", data).then(({ data }) => {
          this.selectedPromotionType = data;
          this.fetchAvailableAwardVehicles();
        }),
        this.$api.getRelatedObject("invitationOnlyDefaultParticipantType", data).then(({ data }) => {
          this.selectedParticipantType = data;
        }),
        this.$api
          .getRelatedObject("forcedAwardVehicle", data)
          .then(({ data }) => {
            this.form.forcedAwardVehicle = data;
          })
          .catch(() => {
            // it's ok to have a 404
          }),
        this.$api
          .getRelatedObject("claimType", data)
          .then(({ data }) => {
            this.selectedClaimType = data;

            return this.fetchClaimFields();
          })
          .catch(() => {
            // it's ok to have a 404
          }),

        this.$api.getRelatedObject("participantTypes", data).then(({ data }) => {
          this.selectedParticipantTypes = data._embedded.participantTypes;
        }),

        this.$api.getRelatedObject("organizationTypes", data).then(({ data }) => {
          this.selectedOrganizationTypes = data._embedded.organizationTypes;
        }),

        this.$api.getRelatedObject("organizationGroups", data).then(({ data }) => {
          this.selectedOrganizationGroups = data._embedded.organizationGroups;
          this.selectedOrganizationGroups.sort((a, b) => a.name.localeCompare(b.name));
        }),

        this.$api.getRelatedObject("duplicationCheckPromotions", data).then(({ data }) => {
          this.selectedDuplicationCheckPromotions = data._embedded.promotions;
        }),

        this.$api
          .post("/api/promotionProducts/search?size=500&page=0&nestedSort=product.productKey,ASC", {
            promotion: { id: data.id }
          })
          .then(({ data }) => {
            this.$set(
              this.promotionProducts,
              "existing",
              this.isCopy
                ? data.content.map(promotionProduct => {
                    return {
                      ...promotionProduct,
                      id: null,
                      promotionProductPayouts: promotionProduct.promotionProductPayouts.map(promotionProductPayout => {
                        return {
                          ...promotionProductPayout,
                          id: null
                        };
                      })
                    };
                  })
                : data.content
            );
            this.$set(this.promotionProducts, "deleted", []);
            return this.$api
              .post("/api/promotionProductGroups/search?size=500&page=0&nestedSort=product.productKey,ASC", {
                promotion: { id: this.form.id }
              })
              .then(({ data }) => {
                data.content.forEach(item => {
                  item.isGroup = true;
                  this.promotionProducts.existing.push(item);
                });
              });
          }),

        this.$api.getRelatedObject("promotionDeadlines", data).then(({ data }) => {
          this.promotionDeadlines.existing = this.isCopy
            ? data._embedded.promotionDeadlines.map(promotionDeadline => {
                return {
                  ...promotionDeadline,
                  id: null,
                  promotionId: null
                };
              })
            : data._embedded.promotionDeadlines;

          this.promotionDeadlines.existing.sort((a, b) => {
            let aBeginDate = a.effectiveDate ? moment(a.effectiveDate) : null;
            let bBeginDate = b.effectiveDate ? moment(b.effectiveDate) : null;
            if (aBeginDate && bBeginDate) {
              return aBeginDate.isBefore(bBeginDate) ? -1 : 1;
            } else if (aBeginDate) {
              return -1;
            } else if (bBeginDate) {
              return 1;
            }
            return 0;
          });

          this.form.claimDeadlines = this.promotionDeadlines.existing.length;
        }),

        this.$api.getRelatedObject("uploads", data).then(({ data }) => {
          if (this.isCopy) {
            let promiseArray = [];
            let newUploads = [];
            this.loading = true;
            data._embedded.uploads.forEach(upload => {
              let promise = this.$api.postWithCaptcha("/api/uploads/duplicate/" + upload.id).then(({ data }) => {
                newUploads.push(data);
              });
              promiseArray.push(promise);
            });

            Promise.all(promiseArray)
              .then(() => {
                this.promotionUploads.existing = newUploads;
              })
              .catch(error => {
                this.errors = this.$api.getErrorsFromResponse(error);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.promotionUploads.existing = data._embedded.uploads;
          }

          this.promotionUploads.deleted = [];
        }),
        this.$api.getRelatedObject("contest", data).then(({ data }) => {
          this.selectedContest = data;
        }),

        this.$api.getRelatedObject("invitationOnlyDefaultParticipantType", data).then(({ data }) => {
          this.selectedPromotionInvitationSetting.defaultParticipantType = data;
        }),

        this.$api.getRelatedObject("promotionInvitationParticipantRequesters", data).then(({ data }) => {
          this.selectedPromotionInvitationSetting.participantRequesters.existing = data._embedded.participants;
        }),

        this.$api.getRelatedObject("promotionInvitationParticipantApprovers", data).then(({ data }) => {
          this.selectedPromotionInvitationSetting.participantApprovers.existing = data._embedded.participants;
        }),

        this.$api.getRelatedObject("promotionInvitationParticipantTypesManagement", data).then(({ data }) => {
          this.selectedPromotionInvitationSetting.participantTypesManagement = data._embedded.participantTypes;
        }),

        this.$api.getRelatedObject("promotionInvitationEmailTemplate", data).then(({ data }) => {
          this.selectedPromotionInvitationSetting.emailTemplate = data;
        }),

        this.$api.getRelatedObject("promotionInvitationApproverEmailTemplate", data).then(({ data }) => {
          this.selectedPromotionInvitationSetting.approverEmailTemplate = data;
        }),

        this.$api.getRelatedObject("promotionInvitationRejectedEmailTemplate", data).then(({ data }) => {
          this.selectedPromotionInvitationSetting.rejectedEmailTemplate = data;
        }),
        this.$api
          .getRelatedObject("promotionInvitationRedeemedEmailTemplate", data)
          .then(({ data }) => {
            this.selectedPromotionInvitationSetting.redeemedEmailTemplate = data;
          })
          .catch(() => {
            console.log("No Promotion Invitation Redeemed Email Template found");
          })
      ]).catch(error => console.log(error));
    },
    fetchAvailableAwardVehicles() {
      if (this.selectedPromotionType) {
        this.$api
          .get("/api/promotionTypes/" + this.selectedPromotionType.id + "/availableAwardVehicles")
          .then(({ data }) => {
            this.availableAwardVehicles = data._embedded.awardVehicles;
          });
      }
    },
    fetchClaimFields() {
      if (this.selectedClaimType) {
        return this.$api
          .get("/api/claimFields/byClaimTypeId/" + this.selectedClaimType.id + "?sort=rankOrder,ASC")
          .then(({ data }) => {
            this.claimFields = data.content;
          });
      } else {
        return Promise.resolve();
      }
    },
    validateSalesNewsNumber(salesNewsNumber) {
      Vue.set(this.errors, "promotionKey", []);
      if (salesNewsNumber && salesNewsNumber.length > 0) {
        this.$api.post("/api/promotions/search?size=1", { promotionKey: salesNewsNumber }).then(({ data }) => {
          if (data.totalElements > 0) {
            let existingPromotion = data.content[0];
            if (this.isNew || this.form.id != existingPromotion.id) {
              Vue.set(this.errors, "promotionKey", ["Promotion Number is already used"]);
            }
          }
        });
      }
    },
    duplicate() {
      this.$router.push({ name: "salesnews", params: { id: 0, copyDataFromId: this.$route.params.id } });
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: "Promotion Library",
            to: { name: "salesnewsLibrary" },
            exact: true
          },
          { text: "New Promotion" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: "Promotion Library",
            to: { name: "salesnewsLibrary" },
            exact: true
          },
          { text: this.form.name }
        ]);
      }
    },

    validateAudience() {
      let countSelectedOrganizationTypes = this.selectedOrganizationTypes ? this.selectedOrganizationTypes.length : 0;
      let countSelectedOrganizationGroups = this.selectedOrganizationGroups
        ? this.selectedOrganizationGroups.length
        : 0;
      let countSelectedParticipantTypes = this.selectedParticipantTypes ? this.selectedParticipantTypes.length : 0;
      let totalCount = countSelectedOrganizationTypes + countSelectedOrganizationGroups + countSelectedParticipantTypes;
      if (totalCount == 0) {
        return "Select at least 1 type of audience";
      } else {
        return true;
      }
    },
    requireUpload() {
      if (this.promotionUploads.existing.length > 0) {
        return true;
      } else {
        return "At least 1 upload is required";
      }
    },
    requireProduct() {
      if (this.promotionProducts.existing.length > 0) {
        let hasPayout = false;
        this.promotionProducts.existing.forEach(promotionProduct => {
          if (promotionProduct.promotionProductPayouts && promotionProduct.promotionProductPayouts.length > 0) {
            hasPayout = true;
          }
        });
        if (hasPayout) {
          this.hasProductPayouts = true;
          return true;
        } else {
          this.hasProductPayouts = false;
          return "At least 1 product with a payout is required";
        }
      } else {
        this.hasProductPayouts = false;
        return "At least 1 product is required";
      }
    },
    onPublish() {
      if (this.publishOption == 1) {
        this.$api
          .patch("/api/promotions/" + this.$route.params.id, {
            publishDate: new Date().toISOString()
          })
          .then(() => {
            this.$router.push({ name: "salesnewsLibrary" });
          });
      } else if (this.publishOption == 2) {
        this.$api
          .patch("/api/promotions/" + this.$route.params.id, {
            publishDate: this.publishDate
          })
          .then(() => {
            this.$router.push({ name: "salesnewsLibrary" });
          });
      }
    },
    showPublishingDialog() {
      if (this.form.publishDate) {
        this.dialogUnpublish = true;
      } else {
        this.dialogPublish = true;
      }
    },
    onUnpublish() {
      this.$api
        .patch("/api/promotions/" + this.$route.params.id, {
          publishDate: null
        })
        .then(() => {
          this.$router.push({ name: "salesnewsLibrary" });
        });
    },
    onDialogConfirmPublishNo() {
      this.dialogConfirmPublish = false;
      this.$router.push({ name: "salesnewsLibrary" });
    },
    checkExistingClaims() {
      if (this.form && this.form.id) {
        this.$api
          .post("/api/claims/search", {
            promotion: {
              id: this.form.id
            }
          })
          .then(({ data }) => {
            if (data.totalElements > 0) {
              this.existingClaims = true;
            }
          });
      } else {
        this.existingClaims = false;
      }
    },
    onPromotionInvitationChange() {
      this.fetchData();
    }
  },
  mounted() {
    setTimeout(() => {
      this.dirty = false;
      this.$nextTick(() => {
        if (this.$refs.promotionParticipantsTable) {
          this.$refs.promotionParticipantsTable.initialize();
        }
        if (this.$refs.promotionOrganizationsTable) {
          this.$refs.promotionOrganizationsTable.initialize();
        }
      });
    }, 1000);
    this.form.effectiveDate = null;
    this.form.expirationDate = null;
  },
  beforeRouteLeave(to, from, next) {
    if (this.dirty) {
      this.toUrl = next;
      this.dialogSaveChanges = true;
    } else {
      next();
    }
  }
};
</script>
